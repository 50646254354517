/**
 * Application entry point.
 *
 * This file includes `import`'s for all the components/directives used
 * by the HTML page and the controller to provide the configuration.
 */

import './sass/desktop.scss';
import './sass/vars_desktop.scss';

import angular from 'angular';
import {isEventUsingCtrlKey} from 'ngeo/utils';
import ngeoMiscToolActivate from 'ngeo/misc/ToolActivate';
import gmfControllersAbstractDesktopController, {
  AbstractDesktopController,
} from 'gmf/controllers/AbstractDesktopController';
import sitjBase from '../sitjmodule';
import sitjAddressModule from 'sitj/address/module';
import sitjRegulargridController from 'sitj/regulargrid/module';
import panels from 'gmfapi/store/panels';
import ngeoMiscFilters from '../filters/Number';

/**
 * @private
 */
class Controller extends AbstractDesktopController {
  /**
   * @param {angular.IScope} $scope Scope.
   * @param {angular.auto.IInjectorService} $injector Main injector.
   * @ngInject
   */
  constructor($scope, $injector) {
    super($scope, $injector);

    /**
     * @type {angular.$locale} $locale Angular locale
     * @private
     */
    this.$locale_ = $injector.get('$locale');
    // Set manually locale number format.
    $scope.$on('$localeChangeSuccess', (ev) => {
      this.$locale_.NUMBER_FORMATS.DECIMAL_SEP = '.';
      this.$locale_.NUMBER_FORMATS.GROUP_SEP = "'";
    });

    const $timeout = $injector.get('$timeout');

    // Open the 'web-component' lidar panel
    $scope.$watch(
      () => this.drawLidarprofilePanelActive,
      (newVal) => {
        if (newVal) {
          panels.openToolPanel('lidar');
        } else {
          panels.closeToolPanel();
        }
      }
    );

    const drawLidarprofilePanelActive = new ngeoMiscToolActivate(this, 'drawLidarprofilePanelActive');
    this.ngeoToolActivateMgr.registerTool('mapTools', drawLidarprofilePanelActive, false);

    // Make visible the footer
    panels.getActiveFooterPanel().subscribe({
      next: (panel) => {
        this.lidarProfileFooterActive = panel === 'lidar';
        $timeout(() => {}); // this triggered on DOM click, we call $timeout to force Angular digest
      },
    });

    /**
     * @type {string}
     * @export
     */
    this.sitjModalUrl;

    /**
     * @type {number}
     * @export
     */
    this.sitjModalWidth;

    /**
     * @type {number}
     * @export
     */
    this.sitjModalHeight;

    /**
     * @type {boolean}
     * @export
     */
    this.sitjModalIsOpen = false;

    /**
     * @type {string}
     * @export
     */
    this.sitjModalTitle;

    /**
     * @param {string} title Title.
     * @param {string} url Url.
     * @param {number} width Width.
     * @param {number} height Height.
     * @export
     */
    this.sitjDisplayWindow = function (title, url, width, height) {
      this.sitjModalUrl = url;
      this.sitjModalWidth = width;
      this.sitjModalHeight = height;
      this.sitjModalTitle = title;
      this.sitjModalIsOpen = true;

      if (this.popupMustRunApply_) {
        this.$scope.$apply();
      }
    }.bind(this);

    // Allow angular-gettext-tools to collect the strings to translate
    /** @type {angular.gettext.gettextCatalog} */
    const gettextCatalog = $injector.get('gettextCatalog');
    // Collect custom translations
    gettextCatalog.getString('type_emplacement');
    gettextCatalog.getString('contenant');
    gettextCatalog.getString('id_defunt');
    gettextCatalog.getString('date_naissance');
    gettextCatalog.getString('date_deces');
    gettextCatalog.getString('date_inhumation');
    gettextCatalog.getString('echeance');
    gettextCatalog.getString('id_etude');
    gettextCatalog.getString('no_ouvrage');
    gettextCatalog.getString('remar_gen');
    gettextCatalog.getString('auteur_proj');
    gettextCatalog.getString('remar_entr');
    gettextCatalog.getString('date_inspection');
    gettextCatalog.getString('periode_controle');
    gettextCatalog.getString('utilite_ouvr');

    $scope.$watch(
      // First arg = value to watch
      () => {
        return this.gmfThemeManager.getThemeName();
      },
      (newTheme, oldTheme) => {
        if (newTheme.toLowerCase() === 'police') {
          this.showRegularGridbutton = true;
        } else {
          this.showRegularGridbutton = false;
        }
      }
    );

    // Display an info window once
    this.loaded = false;

    $scope.$watch(
      () => this.loading,
      () => {
        if (!this.loaded && !this.loading) {
          this.loaded = true;

          // Change this value to add a new info (and create it in `static/news`)
          const infoId = '202408_1';
          const height = 560;
          const width = 815;

          const cookie = 'InfoPopupShown-' + infoId + '=true';

          if (document.cookie.indexOf(cookie) < 0) {
            //            this.sitjDisplayWindow("Information", "/static/cache/informations/?id=" + infoId, width, height);
            this.sitjDisplayWindow(
              'Information',
              'https://geo.jura.ch/informations/?id=' + infoId,
              width,
              height
            );
            document.cookie = cookie + '; max-age=31536000; path=/'; // 1 year: 60*60*24*365 = 31536000 - 1 day = 60*60*24 = 86400
          }

          this.popupMustRunApply_ = true;
        }
      }
    );

    // Bindings to sitjExterns
    window['sitjExterns'] = {};
    window['sitjExterns']['openWindow'] = this.sitj;
    window['sitjExterns']['sitj_lubis_url'] = $injector.get('sitjLubisUrl');
    window['sitjExterns']['openWindow'] = this.sitjDisplayWindow;
  }

  /**
   * @param {JQuery.Event} event keydown event
   */
  onKeydown(event) {
    if (event && isEventUsingCtrlKey(event) && event.key === 'p') {
      this.printPanelActive = true;
      event.preventDefault();
    }
  }
}

/**
 * @hidden
 */
const sitjModule = angular.module('Appdesktop', [
  sitjBase.name,
  sitjRegulargridController.name,
  sitjAddressModule.name,
  ngeoMiscFilters.name,
  gmfControllersAbstractDesktopController.name,
]);

sitjModule.value('gmfContextualdatacontentTemplateUrl', 'gmf/contextualdata');
sitjModule.run(
  /**
   * @ngInject
   * @param {angular.ITemplateCacheService} $templateCache
   */
  ($templateCache) => {
    // @ts-ignore: webpack
    $templateCache.put('gmf/contextualdata', require('./contextualdata.html'));
    //    $templateCache.put('gmf/authentication', require('./authentication.html'));
  }
);

sitjModule.controller('DesktopController', Controller);

export default sitjModule;
